import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const EditAssignedButton = (props) => {

  const getTooltipMessage = () => {
    if (props.deletedAt) {
        return "Cannot assign a deleted designation";
    } else if (props.flag === "exception") {
        return "Cannot assign an Exception designation";
    } else if (!props.userEmail) {
      return "Cannot assign a designation without a user";
    } else if (props.assigned) {
        return "Cannot change assignment on an assigned designation";
    } else if (!props.canAssign) {
        return "You must be an Administrative Manager to take this action";
    } else {
        return "Click to edit";
    }
  }

  const disabled = props.deletedAt || props.flag === "exception" || props.assigned || !props.userEmail || !props.canAssign;
  const tooltip = getTooltipMessage();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  return (
    <div className="text-nowrap">
      <small className="text-muted">{props.decorateAssigned}</small>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 150, hide: 250 }}
        overlay={renderTooltip}
      >
        <span>
          <Button
            className="btn btn-secondary btn-sm bg-light text-dark ms-1"
            disabled={disabled}
            onClick={props.onClick}>
            <i className="fa-solid fa-pen"></i>
          </Button>
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default EditAssignedButton;
