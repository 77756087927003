import React from "react";
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import JiraTicket from "../JiraTicket";

const EditDesignationAssignedFormFields = (props) => {
  return (
    <React.Fragment>
      <p>{`Are you sure you want to assign the Designation for ${props.data.id_340b}, PID: ${props.data.pid}, ${props.data.manufacturer_name}?`}</p>
      <Form.Group>
        <Form.Control type="hidden" id={`designation-${props.data.id}-assigned`} name="designation[assigned]" value={true} />
      </Form.Group>
      <JiraTicket />
    </React.Fragment>
  );
};

export default EditDesignationAssignedFormFields;
