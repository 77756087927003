import React from "react";
import { Form } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";

const EditDesignationFlagFormFields = (props) => {
  return (
      <React.Fragment>
        <Form.Group controlId="flag">
          <Form.Label>Designation Flag</Form.Label>
          <Form.Select as="select" name="designation[flag]" defaultValue={props.data.flag}>
            <option value="">No Flag</option>
            {props.flag_options.map((flag) => (
              <option key={flag} value={flag}>
                {titleCase(flag)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </React.Fragment>
  );
};

export default EditDesignationFlagFormFields;
